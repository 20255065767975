// Partials importieren
@import 'partials/_base';
@import 'partials/_colors';
@import 'partials/_variables';


.react-joyride__tooltip button {
  outline: none !important;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  color: rgba($white, 1) !important;
}